import React, { lazy } from 'react';
import ReactDOM from 'react-dom/client';
import 'sweetalert2/src/sweetalert2.scss'
const LazyApp = lazy(() => import("./App"));

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <LazyApp />
  </React.StrictMode>
);
